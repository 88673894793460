// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~jquery-ui/dist/themes/black-tie/jquery-ui.min';

@import 'quiz';


#app {
    min-height: 100vh;
}