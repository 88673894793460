.quiz-row {
    &.difficulty-hard, &.difficulty-hardcore {
        .quiz-question {
            &:not(.d-none) {
                .image-wrapper {
                    opacity: 1;
                    animation-name: make-invisible;
                    animation-duration: 100ms;
                    animation-delay: 1000ms;
                    animation-fill-mode: forwards;
                    transition: all 100ms ease-in-out;
                }
                .quiz-answer {
                    opacity: 0;
                    animation-name: make-visible;
                    animation-duration: 100ms;
                    animation-delay: 1000ms;
                    animation-fill-mode: forwards;
                    transition: all 100ms ease-in-out;
                }
            }
        }
    }
    &.difficulty-hardcore {
        .quiz-question {
            &:not(.d-none) {
                .image-wrapper {
                    animation-duration: 50ms;
                    animation-delay: 100ms;
                }
                .quiz-answer {
                    animation-duration: 50ms;
                    animation-delay: 100ms;
                }
            }
        }
    }
}

@keyframes make-visible {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes make-invisible {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}